import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import { initializeIcons } from "../lucide_icons";

export default class extends Controller {
  static targets = ["resultsContainer"];

  static values = {
    urls: { type: Array, default: [] },
    loadingUrls: { type: Array, default: [] },
    loadingIds: { type: Array, default: [] },
    openClass: String,
  };

  initialize() {
    this.timeout = null;
    this.pendingRequests = [];
  }

  fetch({ currentTarget }) {
    clearTimeout(this.timeout);

    const accountId = currentTarget.dataset.accountId;

    this.timeout = setTimeout(() => {
      this.fetchResults(currentTarget, accountId);
    }, 500);
  }

  slowClose() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.close();
    }, 200);
  }

  close() {
    this.resultsContainerTarget.classList.remove(this.openClassValue);
  }

  reopen({ target: { value } }) {
    if (value !== "") {
      this.open();
    }
  }

  open() {
    this.resultsContainerTarget.classList.add(this.openClassValue);
  }

  makeRequest(url, value, accountId) {
    const controller = new AbortController();

    get(url, {
      responseKind: "turbo-stream",
      query: {
        query: value,
        account_id: accountId === undefined ? "" : accountId,
      },
      signal: controller.signal,
    }).then(initializeIcons);

    this.pendingRequests.push(controller);
  }

  abortRequests() {
    this.pendingRequests.forEach((controller) => {
      controller.abort();
    });

    this.pendingRequests = [];
  }

  startLoading() {
    this.loadingIdsValue.forEach((id) => {
      const turboFrame = document.getElementById(id);
      if (turboFrame) {
        turboFrame.innerHTML = "...searching";
      }
    });
  }

  fetchResults({ value }, accountId) {
    if (value === "") {
      this.close();
    } else {
      this.open();
      this.startLoading();
      this.abortRequests();
      this.urlsValue.forEach((url) => {
        this.makeRequest(url, value, accountId);
      });
    }
  }
}
