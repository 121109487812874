import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["top", "bottom", "content", "modal", "changes", "errors"];

  static values = {
    initialErrors: { type: Boolean, default: false },
    initialChangedFields: { type: Array, default: [] },
    editing: { type: Boolean, default: false },
    changedFields: { type: Array, default: [] },
  };

  getForm = () => this.contentTarget.querySelector("form");

  connect() {
    const form = this.getForm();
    form.addEventListener("input", this.processInput);
    form.addEventListener("change", this.processInput);
    form.addEventListener("reset", this.resetForm);

    this.adjustElementSize(".editing-mode__top");
    this.adjustElementSize(".editing-mode__bottom");
    this.adjustElementSize(".editing-mode__modal");
  }

  calculateNewWidth() {
    const leftNavElement = document.querySelector(".left-nav");
    let leftNavWidth = window.getComputedStyle(leftNavElement).width;

    leftNavWidth = parseInt(leftNavWidth.slice(0, -2));
    let newWidth = `calc(100% - ${leftNavWidth}px)`;
    return { leftNavWidth, newWidth };
  }

  adjustElementSize(elementSelector) {
    const element = document.querySelector(elementSelector);
    const { leftNavWidth, newWidth } = this.calculateNewWidth();

    element.style.width = newWidth;
    element.style.left = `${leftNavWidth}px`;
  }

  addChangedField = (element) => {
    if (!this.changedFieldsValue.includes(element.id)) {
      this.changedFieldsValue = [...this.changedFieldsValue, element.id];
    }
  };

  resetChangedFields = () => {
    this.changesTarget.innerHTML = "";
    this.changedFieldsValue = [];
  };

  processInput = ({ target }) => {
    if (target.getAttribute("readonly") === "readonly") return;
    if (target.hasAttribute("data-editing-mode-ignore-field")) return;
    if (target.closest(".simple-drawer") !== null) return;

    if (!this.editingValue) {
      this.resetChangedFields();
      this.addChangedField(target);
      this.editingValue = true;
    } else {
      this.addChangedField(target);
    }
  };

  attachGlobalListeners = () => {
    document.querySelectorAll("a, button").forEach((element) => {
      element.addEventListener("click", this.processGlobalClick, true);
    });
  };

  removeGlobalListeners = () => {
    document.querySelectorAll("a, button").forEach((element) => {
      element.removeEventListener("click", this.processGlobalClick);
    });
  };

  processGlobalClick = (event) => {
    const target = event.currentTarget
    if (this.editingValue && (!this.element.contains(target) || ("turboFrame" in target.dataset || "action" in target.dataset))) {
      event.preventDefault();
      this.showCancelWarningModal();
    }
  };

  showEditingElements = () => {
    this.contentTarget.classList.add("editing-mode__content--editing");
    this.topTarget.classList.add("editing-mode__top--visible");
    this.bottomTarget.classList.add("editing-mode__bottom--visible");
  };

  hideEditingElements = () => {
    this.contentTarget.classList.remove("editing-mode__content--editing");
    this.topTarget.classList.remove("editing-mode__top--visible");
    this.bottomTarget.classList.remove("editing-mode__bottom--visible");
  };

  triggerReset = () => {
    this.getForm().dispatchEvent(new Event("reset"));
  };

  resetForm = () => {
    if (this.initialErrorsValue) {
      window.location.reload();
    }
    this.editingValue = false;
    this.contentTarget.querySelector("form").reset();
  };

  renderChangedField = (field) => {
    const form = this.getForm();
    let labelElement =
      form.querySelector(`label[for="${field}"]`) ||
      form.querySelector(`#${field}`);
    const label =
      labelElement?.getAttribute("data-editing-mode-alt-text") ||
      labelElement?.innerText;
    return `<li>${label || field}</li>`;
  };

  displayChangedFields = () => {
    const labels = [
      ...this.changedFieldsValue,
      ...this.initialChangedFieldsValue,
    ].map(this.renderChangedField);

    const uniqueLabels = [...new Set(labels)];
    this.changesTarget.innerHTML = uniqueLabels.join("");
  };

  showCancelWarningModal = () => {
    this.displayChangedFields();
    this.modalTarget.classList.add("editing-mode__modal--visible");
  };

  hideCancelWarningModal = () => {
    this.modalTarget.classList.remove("editing-mode__modal--visible");
  };

  hideErrorsModal = () => {
    this.errorsTarget.classList.remove("editing-mode__modal--visible");
    this.editingValue = true;
  };

  saveChanges = () => {
    this.getForm().submit();
    this.editingValue = false;
  };

  editingValueChanged(editing) {
    if (editing) {
      this.showEditingElements();
      this.attachGlobalListeners();
    } else {
      this.hideEditingElements();
      this.hideCancelWarningModal();
      this.removeGlobalListeners();
    }
  }
}
