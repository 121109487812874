import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  select(event) {
    event.preventDefault();
    const dataset = event.target.closest("a").dataset;
    const value = dataset.value;
    const elementId = dataset.fieldId;

    let field = document.getElementById(elementId);

    field.value = value;
  }

  selectWithTarget(event) {
    event.preventDefault();
    const dataset = event.target.closest("a").dataset;
    const value = dataset.value;
    const elementId = dataset.fieldId;

    let field = document.querySelector(`[data-target="${elementId}"]`);

    field.value = value;
  }

  setSearchValue(event) {
    event.preventDefault();
    const dataset = event.target.closest("a").dataset;
    const label = dataset.label;
    const elementId = dataset.searchId;

    let field = document.getElementById(elementId);

    field.value = label;
  }
}
