import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "label",
    "options",
    "option",
    "field",
    "input",
    "inputContainer",
    "none",
  ];

  static values = {
    open: { type: Boolean, default: false },
  };

  connect() {
    this.listenForReset();
    this.removeListeners();
    this.setupInitial();
  }

  setupInitial() {
    const fieldValue = this.fieldTarget.value;

    const selectedOption = this.findDOMOption(fieldValue);
    this.filterDOMOptions("");

    if (selectedOption) {
      this.setSelectedOption(selectedOption);
    } else {
      this.updateLabel(this.data.get("default") || "None Selected");
    }
  }

  parseOptions(options) {
    let parsedOptions = JSON.parse(options);
    parsedOptions = parsedOptions.filter(
      (option) => option[0] !== null && option[1] !== null
    );
    return parsedOptions;
  }

  options() {
    return this.parseOptions(this.data.get("options"));
  }

  removeListeners() {
    const stopPropagation = (event) => {
      event.stopPropagation();
    };

    this.inputTarget.addEventListener("change", stopPropagation);
    this.inputTarget.addEventListener("input", stopPropagation);
  }

  findOptions(string) {
    const options = this.options();
    const option = options.find((option) => {
      const value = option[1];

      return typeof value === "string"
        ? value.toLowerCase().includes(string.toLowerCase())
        : string === parseInt(selectedOption);
    });
    return option[1];
  }

  findDOMOption(value) {
    const selectedOption = this.optionsTarget.querySelector(
      `[data-value="${value}"]`
    );
    return selectedOption;
  }

  open() {
    this.optionsTarget.classList.add("searchable-dropdown__options--visible");
    this.inputContainerTarget.classList.add(
      "searchable-dropdown__input-container--visible"
    );
    this.openValue = true;
  }

  close() {
    this.optionsTarget.classList.remove(
      "searchable-dropdown__options--visible"
    );
    this.inputContainerTarget.classList.remove(
      "searchable-dropdown__input-container--visible"
    );
    this.openValue = false;
  }

  toggle() {
    if (this.openValue) {
      this.close();
    } else {
      this.open();
    }
  }

  ignoreClicks(event) {
    // ignore clicks on the input and the none option
    event.target === this.inputTarget ||
      event.target.parentElement === this.noneTarget;
  }

  clickContainer(event) {
    if (this.ignoreClicks(event)) {
      return;
    }
    this.toggle();
    if (this.openValue) {
      this.inputTarget.focus();
    }
  }

  updateLabel(value) {
    this.labelTarget.innerHTML = value;
  }

  setField(value) {
    this.fieldTarget.value = value;
  }

  setSelectedOption(domElement) {
    const value = domElement.dataset.value;
    const innerHTML = domElement.innerHTML;
    this.updateLabel(innerHTML);
    this.setField(value);
  }

  clickOption(event) {
    this.fieldTarget.dispatchEvent(new Event("change", { bubbles: true }));
    event.stopPropagation();
    this.setSelectedOption(event.currentTarget);
    this.close();
  }

  performSearch() {
    const searchTerm = this.inputTarget.value.toLowerCase();
    this.filterDOMOptions(searchTerm);
  }

  clickOutside = (event) => {
    if (!this.element.contains(event.target)) {
      this.close();
    }
  };

  showNoResults() {
    this.noneTarget.classList.remove("searchable-dropdown__option--hidden");
  }

  hideNoResults() {
    this.noneTarget.classList.add("searchable-dropdown__option--hidden");
  }

  filterDOMOptions(searchTerm) {
    this.hideNoResults();

    let visibleCount = this.optionTargets.length;
    this.optionTargets.forEach((option) => {
      const includesSearchTerm = option.textContent
        .toLowerCase()
        .includes(searchTerm);

      if (includesSearchTerm) {
        option.classList.remove("searchable-dropdown__option--hidden");
      } else {
        option.classList.add("searchable-dropdown__option--hidden");
        visibleCount--;
      }
    });

    if (visibleCount === 0) {
      this.showNoResults();
    }
  }

  listenForReset() {
    this.fieldTarget.form?.addEventListener(
      "reset",
      this.setupInitial.bind(this)
    );
  }
}
